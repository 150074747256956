<!-- Bact_37 -->

<template>
  <div class="info-page">
    <course-info-modal :open="true"></course-info-modal>
  </div>
</template>

<script>
import CourseInfoModal from "@/core/CourseInfoModal/CourseInfoModal.vue";
import { mapActions } from "vuex";

export default {
  name: "CourseInfo",

  components: { CourseInfoModal },

  data: function() {
    return {};
  },

  created() {
    this.selectCourseModal(this.$route.params.id);
  },

  methods: {
    ...mapActions("CourseInfoModal", ["selectCourseModal"])
  }
};
</script>

<style>
.info-page {
  background-color: #f1efef;
}
.v-application a {
  color: white;
}
</style>
