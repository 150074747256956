<template>
  <div id="parent-component">
    <div
      container="#parent-component"
      :id="'modal-courses-1'"
      class="ACourses-modal"
      uk-modal
      :class="[
        $i18n.locale == 'en' ? 'en-modal' : '',
        open ? 'uk-open d-block p-static' : ''
      ]"
    >
      <div class="uk-modal-dialog" :id="courseModal.course_id">
        <!-- registering -->
        <div class="uk-inline">
          <img
            class="bg-back"
            :src="getBased(courseModal.course_thumbnail)"
            alt=""
          />
          <div class="uk-overlay-default uk-position-cover"></div>
          <div class="uk-position-top-left">
            <button class="uk-modal-close btn-close" type="button">
              <span uk-icon="icon: chevron-left; ratio: 2"></span>
            </button>
          </div>
          <div class="uk-position-center uk-text-center">
            <h2>{{ courseModal.course_title }}</h2>
            <div>
              <div v-if="courseModal.enrolled">
                <div
                  v-if="courseModal.course_type === 'online'"
                  @click="startNow"
                  class="uk-modal-close"
                >
                  <div class="uk-btn-blue">{{ $t("startNow") }}</div>
                </div>
                <div v-else class="uk-btn-blue no-cursor">
                  {{ $t("registered") }}
                </div>
              </div>
              <div v-else @click="enroll" class="uk-btn-blue">
                {{ $t("registerNow") }}
              </div>
            </div>
          </div>

          <div class="uk-position-bottom-left">
            <ul class="uk-iconnav">
              <li @click.prevent="copyPath">
                <a :href="'course-info/' + courseModal.course_id">
                  <svg
                    width="40.603"
                    height="37.851"
                    viewBox="0 0 40.603 37.851"
                  >
                    <g id="noun_Share_1483105" transform="translate(0)">
                      <path
                        id="Path_741"
                        data-name="Path 741"
                        d="M13.229,46.136a6.726,6.726,0,0,0,3.539-.983L27.042,51a3.151,3.151,0,0,0,1.475.393,2.95,2.95,0,0,0,2.556-1.475,2.981,2.981,0,0,0-1.131-4.031L20.16,40.335a9.576,9.576,0,0,0,.1-1.229c0-.344-.049-.639-.049-.983l9.93-6.046a2.934,2.934,0,0,0-3.048-5.014l-10.077,6.1a7.131,7.131,0,0,0-3.785-1.131,7.054,7.054,0,0,0,0,14.108Zm0-8.16A1.131,1.131,0,1,1,12.1,39.106,1.112,1.112,0,0,1,13.229,37.976Z"
                        transform="translate(-6.2 -19.935)"
                        fill="#1d1d1b"
                      />
                      <path
                        id="Path_742"
                        data-name="Path 742"
                        d="M67.229,13a7.029,7.029,0,1,0,7.029,7.029A7.028,7.028,0,0,0,67.229,13Zm0,8.209a1.131,1.131,0,1,1,1.131-1.131A1.112,1.112,0,0,1,67.229,21.209Z"
                        transform="translate(-33.656 -13)"
                        fill="#1d1d1b"
                      />
                      <path
                        id="Path_743"
                        data-name="Path 743"
                        d="M67.229,61.3a7.054,7.054,0,1,0,7.029,7.079A7.028,7.028,0,0,0,67.229,61.3Zm0,8.209a1.131,1.131,0,1,1,1.131-1.131A1.112,1.112,0,0,1,67.229,69.509Z"
                        transform="translate(-33.656 -37.557)"
                        fill="#1d1d1b"
                      />
                    </g>
                  </svg>
                </a>
              </li>
              <li>
                <a @click="addCourseToWishListMethod">
                  <svg width="36.171" height="29.78" viewBox="0 0 36.171 29.78">
                    <path
                      id="noun_Love_2433259"
                      d="M40.266,18.093a9.614,9.614,0,0,1-2.911,7.3L24.927,37.489a3.882,3.882,0,0,1-5.486,0L7.026,25.4A9.569,9.569,0,0,1,7.7,11.034a10.209,10.209,0,0,1,13.541.8,1.339,1.339,0,0,0,1.876,0,10.209,10.209,0,0,1,13.541-.8,9.666,9.666,0,0,1,3.6,7.058Zm-28.278.718a2.536,2.536,0,0,1,.75-1.456,2.7,2.7,0,0,1,1.294-.692,1.295,1.295,0,0,0-.582-2.523,5.324,5.324,0,0,0-2.5,1.365,5.092,5.092,0,0,0-1.507,2.97,1.294,1.294,0,0,0,1.067,1.469h.181A1.294,1.294,0,0,0,11.988,18.811Z"
                      transform="translate(-4.104 -8.844)"
                      :fill="courseModal.wished === true ? '#e00' : '#1d1d1b'"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="contant-modal">
          <h2>{{ $t("welcomeToCourse") }} {{ courseModal.course_title }}</h2>
          <!-- offline info -->
          <template v-if="courseModal.course_type == 'offline'">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      <span
                        ><img :src="nounDate" alt="" />
                        {{ $t("startsAt") }}:</span
                      >
                    </th>
                    <th>
                      <span
                        ><img :src="nounSeat" alt="" />
                        {{ $t("remainingSeats") }}:
                      </span>
                    </th>
                    <th>
                      <span
                        ><img :src="nounLocation" alt="" />
                        {{ $t("address") }}:</span
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(offlineInfo, index) in courseModal.offline"
                    :key="index"
                  >
                    <td>
                      <h4 class="d-inline">{{ index + 1 }}.</h4>
                      {{
                        offlineInfo.offlineCourse_start_date === null
                          ? offlineInfo.offlineCourse_start_date
                          : offlineInfo.offlineCourse_start_date.toLocaleString()
                      }}
                    </td>
                    <td>
                      {{ offlineInfo.offlineCourse_capacity }}
                      {{ $t("seats") }}
                    </td>
                    <td>
                      {{ offlineInfo.offlineCourse_address }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <!-- description -->
          <div>
            <div>
              <h4>{{ $t("golasOfCourse") }}</h4>
              <p>{{ courseModal.course_targets }}</p>
            </div>
            <div>
              <h4>{{ $t("requirementsToPassTheCourse") }}</h4>
              <p>{{ courseModal.course_requiremnts }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../main";
import { mapActions, mapState } from "vuex";

// for all notifications in this component
function notification(group, type, title, duration) {
  return EventBus.$notify({
    group: group,
    type: type,
    title: title,
    duration: duration
  });
}

export default {
  name: "CourseInfoModal",

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      nounDate: require("../../assets/img/ACourses/noun_date.svg"),
      nounSeat: require("../../assets/img/ACourses/noun_Seat.svg"),
      nounLocation: require("../../assets/img/ACourses/noun_Location.svg")
    };
  },

  methods: {
    ...mapActions("CourseInfoModal", ["addCourseToWishList", "enrollCourse"]),

    addCourseToWishListMethod() {
      if (this.courseModal.wished === true) return;
      this.addCourseToWishList({ courseId: this.courseModal.course_id });
    },

    startNow() {
      if (this.courseModal.online.index.length) {
        return this.$router.push({
          path: "/courses/" + this.courseModal.course_id
        });
      } else {
        return notification(
          "public",
          "error",
          this.$t("noCourseLeactures"),
          4000
        );
      }
    },

    enroll() {
      this.enrollCourse(this.courseModal.course_id)
        .then(() => {
          setTimeout(() => {
            this.$router.push({ path: "/profile/edit?tab=bills" });
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            this.$router.push({ path: "/login" });
          }, 1000);
        });
    },

    copyPath() {
      try {
        var success = navigator.clipboard.writeText(
          location.hostname + "/course-info/" + this.courseModal.course_id
        );
        if (success) {
          notification("public", "success", this.$t("copyToClipboard"), 4000);
        }
      } catch (e) {
        notification("public", "error", this.$t("failedToCopy"), 4000);
      }
    }
  },

  computed: {
    ...mapState("CourseInfoModal", ["courseModal"])
  }
};
</script>

<style scoped lang="scss">
.uk-modal {
  z-index: 990;
}
.en-modal {
  direction: ltr !important;
}
.v-data-table {
  margin: 20px 0;
  * {
    text-align: center !important;
    font-size: 16px !important;
  }
}
.no-cursor {
  cursor: text !important;
}
.uk-btn-blue {
  cursor: pointer;
}
.p-static {
  position: static;
  background-color: transparent;
}
</style>
